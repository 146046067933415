/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


@tailwind base;
@tailwind components;
@tailwind utilities;


/* Common */

/* .fXiJIw {
  position: absolute;
  bottom: 0;
  width: -webkit-fill-available!important;
  margin: 0px 20px;
} */


::-webkit-scrollbar {
  width: 0px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 50px;  
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(199, 198, 198); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


/* Loader styles */ 

.circle {
    display: inline-block;
    background-color: #bae6fd;
    height: 15px;
    width: 15px;
    border-radius: 25px;
  }
  

#ball-1{
    animation: bounce 1s  infinite;
    }
    #ball-2{
     animation: bounce 1.1s  infinite;
    
    }
    #ball-3{
    animation: bounce 1.2s  infinite;
    
    }
    
    @keyframes bounce {
      0% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(23px);
      }
      60% {
        transform: translateY(-25px);
      }
      80%{
        transform: translateY(0);
      }
    }
    

/* Registration */
.css-t3ipsp-control:hover{
  border-color: #cbd5e1!important;

}
.css-t3ipsp-control {
  box-shadow:0 0 0 1px transparent!important;
  border-color: #cbd5e1;
  height: 100%;

}
.css-13cymwt-control {
  outline: none!important;
  outline-offset: 0px!important;
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
  height: 100%;

}
.elements .css-1jqq78o-placeholder {
text-align: left;
}
/* .css-t3ipsp-control:active {
  outline: none;
  border: none;
  } */

  .tbl-selection .rdt_TableRow {
    cursor: pointer;

  }
  .tbl-selection .rdt_TableRow:hover{
    background-color:#f0f3ff;
  }

